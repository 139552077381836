<template>
    <!-- 雷达图 -->
    <div>
      <dv-border-box-13 style="width:100%;height:400px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
        <div ref="echarts_3" style="height:80%;width:100%"></div>
      </dv-border-box-13>
    </div>
   </template>
   
   <script>
   import * as echarts from 'echarts'
   
   export default {
       data(){
           return {
               items:[]
           }
       },
       mounted(){
           // 健康指数 雷达图
            const echarts3 = echarts.init(this.$refs.echarts_3)
            const option = {
                tooltip: {},
                legend: {
                    data: ['纳尔实业', '平安保险','招商银行'],
                    x:"center",
                    y:'bottom',
                    textStyle:{
                        color:"#fff"
                    }
                },
                color: ['red', 'yellow', 'blue'],
                radar: {
                    name:{
                        textStyle: {
                            //设置颜色
                            color:'#fff'
                        }
                    },
                    indicator: [
                    { name: '投资关系', max: 100, color:'#fff'},
                    { name: '操盘模式', max: 100, color:'#fff' },
                    { name: '财务状态', max: 100, color:'#fff' },
                    { name: '运行状态', max: 100, color:'#fff' },
                    { name: '规模扩张', max: 100, color:'#fff' },
                    { name: '声誉风险', max: 100, color:'#fff' },
                    { name: '外部环境', max: 100, color:'#fff' }
                    ],
                    center: ['50%','50%'],
                    radius: "58%"
                },
                series: [{
                    name: '',
                    type: 'radar',
                    itemStyle : {
                        normal : {
                            splitLine: {
                                lineStyle: {

                                }
                            },
                            label: {
                                show: false,
                                textStyle:{
                                },
                                formatter:function(params) {
                                    return params.value;}
                            },
                        }
                    },
                    data : [
                        {
                            value : [57.17727272727273,40,27.6,62.900000000000006,10,75,50],
                            name : '郑州银行'
                        },
                        {
                            value : [48.81363636363636,50,44,61.56999999999999,10,75,50],
                            name : '宁波银行'
                        },
                        {
                            value : [46.2,25,38.4,58.91,10,75,50],
                            name : '苏州银行'
                        }
                    ]
                }]
            };
            echarts3.setOption(option)
       },
       methods:{
       }
   }
   </script>