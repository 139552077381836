<template>
  <!-- 真实数据宽屏 -->
  <div class="container bg ScaleBox" ref="ScaleBox" 
    :style="{
      width: width + 'px',
      height: height + 'px',
    }">
    <div class="header" style="padding-top: 20px;">
      <div class="header-left" style="padding-left: 180px; display: flex;">
        <img class="logo" src="./assets/logo.png" style="width: 331px;height: 60px;padding-left: 60px;">
      </div>
      <div class="header-title" style="display:flex;justify-self: space-between;margin:10px;">
        <div style="flex:0 1 25%">
        <dv-decoration-10 style="height:5px;" />
        </div>

        <div style="flex:0 1 50%">
          <div class="d-flex">
            <dv-decoration-8 style="height:50px;flex:1;" />
            <h2 style="width: 260px;font-size: 17px;" @click="$router.push('/login');">资本市场系统风险监测与预警平台</h2>
            <!-- <div style="font-size: large;">大规模泛金融知识图谱系统</div> -->
            <dv-decoration-8 :reverse="true" style="height:50px;flex:1;" />
          </div>
          
        </div>

        <div style="flex:0 1 25%">
          <dv-decoration-10 style="height:5px;transform:rotateY(180deg)" />
        </div>
      </div>
      <div class="header-right">

      </div>
    </div>
    <div class="main">
      <!-- 第一块 -->
      <div class="box">
        <div class="box-header" style="">
          <span style=""></span>
          预警监测
        </div>
        <div class=" box-body">
          <!-- 第一行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33.33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">股票市场</h3>
                <div style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">1100</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-right: 30px;" dur=5><h1 style="color: rgba(255,215,0);">1184</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-right: 30px;" dur=5><h1 style="color: rgba(255,215,0);">1254</h1></dv-decoration-9>
                </div> 
                <div style="display: flex;justify-content: center;align-items: center;text-align: center;margin-top: 40px;">
                  <span style="width:120px;height:120px;margin-right:30px;">低风险</span>
                  <span style="width:120px;height:120px;margin-right:30px;">中风险</span>
                  <span style="width:120px;height:120px;margin-right:30px;">高风险</span>
                </div>  
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33.33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">债券市场</h3>
                <div style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">1862</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-right: 30px;" dur=5><h1 style="color: rgba(255,215,0);">441</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-right: 30px;" dur=5><h1 style="color: rgba(255,215,0);">87</h1></dv-decoration-9>
                </div> 
                <div style="display: flex;justify-content: center;align-items: center;text-align: center;margin-top: 40px;">
                  <span style="width:120px;height:120px;margin-right:30px;">低风险</span>
                  <span style="width:120px;height:120px;margin-right:30px;">中风险</span>
                  <span style="width:120px;height:120px;margin-right:30px;">高风险</span>
                </div>  
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33.33%">
              <!-- 饼图 -->
              <!-- <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">个体风险预警</h3>
                <div ref="echarts_1" style="height:80%;width:100%; "></div>
              </dv-border-box-13> -->
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">期货市场</h3>
                <div style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">2035</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-right: 30px;" dur=5><h1 style="color: rgba(255,215,0);">766</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-right: 30px;" dur=5><h1 style="color: rgba(255,215,0);">145</h1></dv-decoration-9>
                </div> 
                <div style="display: flex;justify-content: center;align-items: center;text-align: center;margin-top: 40px;">
                  <span style="width:120px;height:120px;margin-right:30px;">低风险</span>
                  <span style="width:120px;height:120px;margin-right:30px;">中风险</span>
                  <span style="width:120px;height:120px;margin-right:30px;">高风险</span>
                </div>  
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第二行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33.33%" v-for="item in 3" :key="item">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">宏观经济指标</h3>
                <div ref="chartContainer" style="width:100%; height: 100%;margin: 1px"></div>
              </dv-border-box-13>
            </div>
            <!-- <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">宏观经济指标</h3>
                <div ref="chartContainer" style="width:100%; height: 90%;margin: 1px"></div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">宏观经济指标</h3>
                <div ref="chartContainer" style="width:100%; height: 90%;margin: 1px"></div>
              </dv-border-box-13>
            </div> -->
          </div>

          <!-- 第三行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33.33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">预警提示</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33.33%">
              <dv-border-box-13 style="width:100%;height:300px;padding-top: 10px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <div ref="echarts_3" style="height:80%;width:100%"></div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33.33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">行业预警排行</h3>
                <div ref="echarts_2" style="height:90%;width:100%"></div> 
              </dv-border-box-13>
            </div>
          </div>
        </div>
        <!-- <div class="box-footer">
          <span style=""></span>
        </div> -->
      </div>
      <!-- 第二块 -->
      <div class="box">
        <div class="box-header" style="">
          <span style=""></span>
          市场主体概况
        </div>
        <div class="box-body">
          <!-- 第一行 一列 课程概览 -->
          <div class="charts-row">
            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:200px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <!-- <Chart /> -->
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  

              
              </dv-border-box-13>
                <RadarChart/>
            </div>

            <div style="flex:0 1 50%">
              <dv-border-box-1 style="width:100%;height:600px;">
              <!-- 图谱 -->
              <iframe src="/static/3d-force-graph/index1.html" ref="iframe" width="100%" height="600px" scrolling="no"></iframe>
              </dv-border-box-1>
            </div>

            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:200px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">图谱统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:123px;height:123px;margin-right:30px;" dur=5><h2 style="color: rgba(255,215,0);">606041</h2></dv-decoration-9>
                  <dv-decoration-9 style="width:123px;height:123px;margin-left: 30px;" dur=5><h2 style="color: rgba(255,215,0);">1694475</h2></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 60px;">节点总数</span>
                <span style="padding-left: 55px;">边总数</span>
              </div>  
              </dv-border-box-13>

              <dv-border-box-13 style="width:100%;height:400px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">区域分析</h3>
                <Map />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第二行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">舆情预警</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config11" style="width:95%;height:220px;justify-content: center;align-items: center" />
                </div>
              </dv-border-box-13>
            </div>

            <div style="flex:0 1 50%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">系统性风险指数</h3>
                <LineChart />
              </dv-border-box-13>
            </div>

            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <!-- <CapsuleChart :dataChart="items"/> -->
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
                
              </dv-border-box-13>
            </div>
          </div>
        </div>
        <!-- <div class="box-footer">
          <span style=""></span>
        </div> -->
      </div>
      <!-- 第三块 -->
      <div class="box">
        <div class="box-header" style="">
          <span style=""></span>
          系统性风险分析
        </div>
        <div class=" box-body">
          <!-- 第一行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 100%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <!-- 维度时序 -->
                <dimension-component :zoom="datazoom" ref="dimension"/>
                <!-- 系统性风险指数 -->
                <!-- <systemRiskComponent :zoom="datazoom" @datazoom="changeComp" ref="systemRisk"></systemRiskComponent> -->
              </dv-border-box-13>
            </div>
            <!-- <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  
              </dv-border-box-13>
            </div> -->
          </div>

          <!-- 第二行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 100%">
              <dv-border-box-13 style="width:100%;height:300px;padding-top: 4px">
                <!-- <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3> -->
                <el-tabs type="border-card" style='height: 90%;' @tab-click="handleClick">
                  <!-- 收益率传染 -->
                  <el-tab-pane label="个体风险状态" :lazy='true' style="height: 100%;">
                  <market-risk-component :zoom="datazoom" :title="'个体风险状态'" ref="marketRisk"/>
                  </el-tab-pane>
                  <!-- 波动传染 -->
                  <el-tab-pane label="风险关联与溢出" :lazy='true' style="height: 100%;">
                    <market-risk-component1 :zoom="datazoom" :title="'风险关联与溢出'" ref="marketRisk"/>
                  </el-tab-pane>
                  <el-tab-pane label="市场系统结构" :lazy='true' style="height: 100%;">
                    <market-risk-component1 :zoom="datazoom" :title="'市场系统结构'" ref="marketRisk"/>
                  </el-tab-pane>
                  <el-tab-pane label="市场波动和趋势" :lazy='true' style="height: 100%;">
                    <market-risk-component1 :zoom="datazoom" :title="'市场波动和趋势'" ref="marketRisk"/>
                  </el-tab-pane>
                </el-tabs>
                <!-- <market-risk-component :zoom="datazoom" :title="'个体风险状态'"/> -->
                <!-- <dimension-component :zoom="datazoom"/> -->
              </dv-border-box-13>
            </div>
            <!-- <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
              </dv-border-box-13>
            </div> -->
          </div>

          <!-- 第三行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33.33%" v-for="item in 3" :key="item">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">宏观经济指标</h3>
                <div ref="chartContainer" style="width:100%; height: 90%;margin: 1px"></div>
              </dv-border-box-13>
            </div>
            <!-- <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div> -->
          </div>
        </div>
        <!-- <div class="box-footer">
          <span style=""></span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Map from './components/Map.vue'
import RadarChart from './components/RadarChart.vue'
import CapsuleChart from './components/CapsuleChart.vue';
import LineChart from './components/LineChart.vue'
import http from "@/utils/request";
import * as echarts from "echarts";
import { color } from 'd3';
import systemRiskComponent from './components/systemRiskComponent';// 系统风险折线大图
import dimensionComponent from "./components/dimensionComponent";
import marketRiskComponent from "./components/riskIndexComponent";
import marketRiskComponent1 from "./components/riskIndexComponent1";
export default {
  name: 'App',
  components: {
    Map,
    RadarChart,
    CapsuleChart,
    LineChart,
    systemRiskComponent,
    dimensionComponent,
    marketRiskComponent,
    marketRiskComponent1
  },
  data() {
    return {
      compName:'systemRiskComponent',
      datazoom:[],
      scale: 0,
      width: 5760,
      height: 1080,
      config:{
        data: [
          {
            name: '南阳',
            value: 167
          },
          { 
            name: '周口',
            value: 67
          },
          {
            name: '漯河',
            value: 123
          },
          {
            name: '郑州',
            value: 55
          },
          {
            name: '西峡',
            value: 98
          }
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '单位',
        showValue: true
      },
      config2:{
        data: [66, 45],
        shape: 'roundRect'
      },
      config3:{
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 71
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          },
          {
            name: '信阳',
            value: 35
          },
          {
            name: '漯河',
            value: 15
          }
        ],
        img: [
          require('./assets/1st.png'),
          require('./assets/2st.png'),
          require('./assets/3st.png'),
          require('./assets/4st.png'),
          require('./assets/5st.png'),
          require('./assets/6st.png'),
          require('./assets/7st.png'),
        ]
      },
      config4:{
        centerPoint: [0.48, 0.35],
        points: [
          {
            position: [0.52, 0.235],
            text: '新乡'
          },
          {
            position: [0.43, 0.29],
            text: '焦作'
          },
          {
            position: [0.59, 0.35],
            text: '开封'
          },
          {
            position: [0.53, 0.47],
            text: '许昌'
          },
          {
            position: [0.45, 0.54],
            text: '平顶山'
          },
          {
            position: [0.36, 0.38],
            text: '洛阳'
          },
          {
            position: [0.62, 0.55],
            text: '周口'
          },
          {
            position: [0.56, 0.56],
            text: '漯河'
          },
          {
            position: [0.37, 0.66],
            text: '南阳'
          },
          {
            position: [0.55, 0.81],
            text: '信阳'
          },
          {
            position: [0.55, 0.67],
            text: '驻马店'
          },
          {
            position: [0.37, 0.29],
            text: '济源'
          },
          {
            position: [0.20, 0.36],
            text: '三门峡'
          },
          {
            position: [0.76, 0.41],
            text: '商丘'
          },
          {
            position: [0.59, 0.18],
            text: '鹤壁'
          },
          {
            position: [0.68, 0.17],
            text: '濮阳'
          },
          {
            position: [0.59, 0.10],
            text: '安阳'
          }
        ],
        bgImgUrl: require('./assets/map.jpg'),
        centerPointImg: {
          url: require('./assets/mapCenterPoint.png')
        },
        pointsImg: {
          url: require('./assets/mapPoint.png')
        }
      },
      // 表格设置
      config11 : { 
        header: ['主体代码&nbsp', '行业', '&nbsp&nbsp&nbsp预警时刻','&nbsp&nbsp风险水平','风险类型&nbsp'],
        data: [
            {
              主体代码:'688001.SH',
              行业:'计算机',
              预警时刻:'2023/6/29 16:00',
              风险水平:'严重',
              风险类型:'流动风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
          ],
        

      evenRowBGC:'#0A2732',
      oddRowBGC:'0A2732',
      headerBGC:'rgba(61,89,171,0.5)',
      align: ['right'],
      

      },
      config22 :
      { 
        data: [
        ['教育',  7],
        ['金融业',  1],
        ['房地产业',  2],
        ['制造业',  3],
        ['房地产业',  4],
        ['批发和零售业',  5],

      ], 
      evenRowBGC:'#0A2732',
      oddRowBGC:'0A2732',
      

      },
      items:[
          {
          name: '股权质押',
          value: 683
          },
          {
          name: '转让股价',
          value: 234
          },
          {
          name: '股份增持',
          value: 240
          },
          {
          name: '债券发行',
          value: 523
          },
          {
          name: '高管变动',
          value: 345
          },
          {
          name: '产品发布',
          value: 320
          },
          {
          name: '破产',
          value: 280
          },
          {
          name: '业绩下滑',
          value: 271
          },
      ],

      date:[],
      rate:[],
      macro:[],
      montary:[],
      income:[],
      investment:[],
      loan:[],
      lever:[],
      other:[],
      whole:[],
      title:['利率','货币','收入和融资','信贷','宏观指标','银行理财','整体杠杆','其他']
    }
  },
  methods: {
    getScale() {
        // 固定好16：9的宽高比，计算出最合适的缩放比
        const { width, height } = this;
        const wh = window.innerHeight / height;
        const ww = window.innerWidth / width;
        console.log(ww < wh ? ww : wh);
        return ww < wh ? ww : wh;
      },
    setScale() {
      // 获取到缩放比例，设置它
      this.scale = this.getScale();
      if (this.$refs.ScaleBox) {
        this.$refs.ScaleBox.style.setProperty("--scale", this.scale);
      }
    },
    debounce(fn, delay) {
      const delays = delay || 500;
      let timer;
      return function () {
        const th = this;
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
    changeComp(mesg){
        this.datazoom=mesg
        console.log(mesg)
    },
    // handleClick(){
    //   console.log('jinru');
    //   this.$refs.marketRisk.getData()
    // }
  },
  created() {
    
  },
  mounted() {
    // 大屏自适应部分
    this.setScale();
    window.removeEventListener("resize",this.debounce(this.setScale))
    window.addEventListener("resize", this.debounce(this.setScale));

    // 自动登录
    const params = new URLSearchParams();
    params.append('username', 'gongdragon');
    params.append('password', 'gwl123');
    http.post('/users/login/', params)
    .then((res)=>{
      if (res.data.message===1){
        this.loading = false
        let user={token:res.data.data,name:'gongdragon',stats:res.data.stats}
        this.$store.commit('login',user)
      } else {
        this.$message({
          showClose: true,
          message: res.data.data,
          type: 'warning'
        });
        this.loading = false
      }
    }).then(()=>{
      // 获取股票风险 圆圈类饼图部分
    http.get('/warning/get_warning_count/').then((res)=> {
        // console.log('get_warning_count',res);
        
        // this.stockLow=res.data["低风险"]
        // this.stockMid=res.data["中风险"]
        // this.stockHigh=res.data["高风险"]
    })

    // 宏观分析折线图
    http.get('/risk/future_risk/').then((res)=>{
        this.date=res.data.date
        this.rate=res.data.rate
        this.macro=res.data.macro
        this.montary=res.data.montary
        this.income=res.data.income
        this.investment=res.data.investment
        this.loan=res.data.loan
        this.lever=res.data.lever
        this.other=res.data.other
        this.whole.push(this.rate)
        this.whole.push(this.montary)
        this.whole.push(this.income)

        // this.whole.push(this.loan)
        this.whole.push(this.macro)
        this.whole.push(this.investment)
        this.whole.push(this.lever)
        this.whole.push(this.other)
      }).then(()=>{
        for(let i = 0; i < 6; i++) {
        // let chartContainer = 'chartContainer' + i
        let echart = echarts.init(this.$refs.chartContainer[i])

        const option = {
          title:{
            text:this.title[i],
            textStyle:{
            color:'#fff'
            },
            left:'center'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top:30,
            textStyle:{
            color:'#fff'
            },
          },
          xAxis: {
            type: 'category',
            data: this.date,
            axisLabel: {//x轴文字的配置
                show: true,
                textStyle: {
                    color: "#fff",
                }
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {//x轴文字的配置
                show: true,
                textStyle: {
                    color: "#fff",
                }
            },
          },
          grid:{
            containLabel:true
          },
          series: this.whole[i]
        };
        echart.setOption(option)
      }}
      )

    // 轮播图
    http.get('/anomaly/item_anomaly/',{params:{
      sc:'stock',
    }}).then((res)=>{
      // console.log(res.data.data);
      const formatData = res.data.data.map(item => {
        return [item.监测时间,item.名称,item.异常关联类型]
      })
      const config22 = {
        header: ['监测时间', '名称', '异常关联类型'],
        data: formatData,
        // headerBGC:'black',
        evenRowBGC:'#0A2732',
        oddRowBGC:'0A2732',
      }
      // console.log('formatData',formatData);
      this.config22 = config22
    })

    // 健康指数 雷达图
    http.get(
            "/health/health_search/",
            {params:{
                name: '上海纳尔实业股份有限公司',//公司名
                date: '2024-01',//时间（年份+季度）
    }}).then(response => {
      console.log('Response',response);
      
      // let radarDatas// 存放所有数据项
      // radarDatas = response.data.data.map(item => {
      //   // 单个数据项 转化为echarts格式
      //   let radarData = [item.投资关系,item.操盘模式,item.财务状态,item.运行状态,item.规模扩张,item.声誉风险,item.外部环境]
      //   return radarData
      // })
      // console.log('radarDatas',radarDatas)
      let radarData = []
      for (let i in response.data.data) {
        if (i !== '健康指数' && i !== '等级' && typeof response.data.data[i] === 'number') radarData.push(response.data.data[i])
      }
      const echarts3 = echarts.init(this.$refs.echarts_3)
      const option_3 = {
        title: {
          text: '纳尔实业公司  2024-1季度',
          x:'center',//水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          bottom:"-12",
          padding:[ 0,10,10,10],
          textStyle:{
            color:'#fff',
            fontSize: 14
          },
        },
        legend: {
          data: ['中国银行']
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '投资关系', max: 100, color:'#fff'},
            { name: '操盘模式', max: 100, color:'#fff' },
            { name: '财务状态', max: 100, color:'#fff' },
            { name: '运行状态', max: 100, color:'#fff' },
            { name: '规模扩张', max: 100, color:'#fff' },
            { name: '声誉风险', max: 100, color:'#fff' },
            { name: '外部环境', max: 100, color:'#fff' }
          ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value:  radarData,
                name:   '上海纳尔实业股份有限公司'
              },
            ]
          }
        ]
      };
      echarts3.setOption(option_3)
    })

    // 行业预警排行
    const echarts2 = echarts.init(this.$refs.echarts_2)
    http.get('/warning/indus_rank/', {
      params: {
        // exchange:this.exchange
        exchange: '',
        start:'',
        end:'',
      }
    }).then((res) => {

      let xData=[],yData=[]
      let tmp=res.data
      for(let key in tmp)
      {
        if(key.length < 16){
          xData.push(tmp[key])
          yData.push(key)
        }
      }
      const option_2 = {
        grid: {
          left: '34%',//离左边远一点
          right: '4%',
          bottom: '15%',
          top:'5%',//如果不填，会默认一个特别大的值，导致图的上面留白特别大
        },
        xAxis: {
          type: 'value',
          axisLine:{
            lineStyle:{
              color:'#fff'
            }
          },
        },
        yAxis: {
          type: 'category',
          data: yData,
          axisLine:{
            lineStyle:{
              color:'#fff'
            }
          },
        },
        series: [
          {
            // color:'#6d7d87',
            type: 'bar',
            data: xData
          },
        ]
      }
      echarts2.setOption(option_2)
    })

    // // 饼图 个体风险预警
    // const echarts1 = echarts.init(this.$refs.echarts_1)
    //   http.get('/warning/individual_rank/', {
    //     params: {
    //       exchange: '',
    //       start:'',
    //       end:'',

    //     }
    //   }).then((res) => {
    //     let pieData = []
    //     // this.critical = res.data['指数']['严重']
    //     // this.mid = res.data['指数']['中等']
    //     // this.good = res.data['指数']['轻微']
    //     let tmp=res.data['环形图']
    //     for(let key in tmp)
    //     {
    //       let arr={}
    //       arr['name']=key
    //       arr['value']=tmp[key]
    //       pieData.push(arr)
    //     }
    //     echarts1.clear()
    //     const option_1={
    //       tooltip: {
    //         trigger: 'item'
    //       },
    //       legend: {
    //         top: '2%',
    //         left: 'center',
    //         textStyle:{
    //           color: '#fff'
    //         }
    //       },
    //       series: [
    //         {
    //           // name: 'Access From',
    //           type: 'pie',
    //           radius: ['30%', '65%'],//调整饼图内圆和外圆大小
    //           center:['50%','65%'],//调整饼在画布上的距离，此时偏下
    //           avoidLabelOverlap: false,
    //           itemStyle: {
    //             borderRadius: 5,
    //             borderColor: '#fff',
    //             borderWidth: 2
    //           },
    //           label: {
    //             show: true
    //           },
    //           emphasis: {
    //             label: {
    //               show: true,
    //               fontSize: 40,
    //               fontWeight: 'bold'
    //             }
    //           },
    //           labelLine: {
    //             show: true
    //           },
    //           data: pieData
    //         }
    //       ]
    //     }
    //     echarts1.setOption(option_1,true)
    //   })

    // 系统性风险
    // this.$refs.systemRisk.getData()

    // 第三屏上
    this.$refs.dimension.getData()
    // 第三屏中间
    this.$refs.marketRisk.getData()
    }
    )
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body,
input,
button,
a,
textarea,
select {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

}

div {
  box-sizing: border-box;
}

ul li {
  list-style: none;
}
// 大屏自适应
.ScaleBox {
    position: absolute;
    transform: scale(var(--scale)) translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    transform-origin: 0 0;
    left: 50%;
    top: 50%;
    transition: 0.3s;
    z-index: 999;
  }
.bg {
    padding:0;
    background:url('./assets/pageBg.png') center center;
    /* background-size:cover; */
  }
.container {
  color: aliceblue;
  // width: 5760px;
  // height: 1080px;
  // background-color: #18478F;

  >.header {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100px; //

    
    .header-left,
    .header-right {
      flex: 0 1 20%;

      .el-button {
        border: none;
        transform: skewX(45deg);
        width: 180px;
        height: 50px;
        margin-left: 30px;
      }

      .el-button--primary {
        background-color: rgba(0, 102, 172, 0.4);
        border-color: #0066AC;
      }

      .el-button--primary:focus {
        background-color: #BDA158;
      }
    }

    .header-title {
      flex: 0 1 32%
    }
  }
  .main {
  width: 100%;
  height: 980px; //
  display: flex;
  justify-content: space-evenly;

  .box {
    flex: 0 1 32%;
    height: 940px; //
    // background-color: #0C2B61;
    
    .box-header {
      // background-color: #18478F;
      text-align: center;
      height: 40px; //
      line-height: 40px;
      border: 1px solid rgb(40, 110, 216);

      span {
        float: left;
        width: 0;
        height: 0;
        // border-top: 30px solid #1596ca;
        // border-right: 30px solid transparent;
      }
    }

    .box-body {
      height: 850px; //

      .charts-row {
        display: flex;

        .chart-container {
          width: 50%;
          padding-right: 40px;
        }

        .chart-title {
          text-align: left;
          height: 10%; //
          padding-top: 8px;

          .icon {
            width: 15px;
            height: 15px;
            padding-left: 8px;
          }
        }

        .chart {
          height: 90%; //
        }
      }
      .el-tabs__content{
        height: 100%;
        padding: 0;
      }
      .el-tabs--border-card{
        background-color: transparent !important;
        border: transparent !important;
      }
      .el-tabs__header{
        background-color: transparent !important;
        border-bottom: transparent !important;
      }
      .el-tabs__item.is-active{
        background-color: transparent !important;
        border-right-color: transparent !important;
        border-left-color: transparent !important;
      }
    }

    .box-footer {
      height: 30px;
      position: relative;

      span {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 25px solid #1596ca;
        border-left: 25px solid transparent;
      }
    }
  }
  }

}
</style>
